<template>
	<div>
		<BackButton :route="{ name: 'content-services', label: 'Services' }" />

		<ContentBuilder
			ref="builder"
			parent-endpoint="services"
			edit-route="content-services-id"
			api-method="services"
			page-type="Service"
			:clone-id="id"
			:url-prefix="urlPrefix"
			:restrict-url-prefix="true"
			@created="created"
		/>
	</div>
</template>

<script>
// import { ExternalLinkIcon } from '@heroicons/vue/outline'

export default {
	components: {
		// ExternalLinkIcon,
	},
	setup() {},
	data() {
		return {
			urlPrefix: '/our-services/',
			id: this.$route.query.id || null,
		}
	},
	computed: {
		templateSelected() {
			return this.model.template !== '' ? this.model.template : null
		},
	},
	methods: {
		created(slug) {
			this.$router.push({ name: 'content-services-id', params: { id: slug } })
		},
	},
}
</script>
